import {createComponent, createMemo, Component, JSXElement} from 'solid-js';
import {componentMap, ComponentMap} from './component-map';
import convertToVirtualSections from './convert-to-virtual-sections';
import {ErrorComponent} from '../components/error/error';

function convertAttsToProps(Module: ExtendedComponent, attributes: {}, parentProps: ParentProps) {
    if (Module && typeof Module.parseProps === 'function') {
        return Module.parseProps(Object.assign({}, parentProps, attributes));
    }
    return attributes;
}

interface ExtendedComponent extends Component {
    parseProps?: (atts: any) => any;
}

type DynamicModuleGeneratorProps = {
    content: any;
    permalink?: string;
    mobile?: boolean;
    viewport?: string;
    pageTitle?: string;
    skipSectionConversion?: boolean;
};

type ParentProps = {
    permalink?: string;
    mobile?: boolean;
    viewport?: string;
};

export type ComponentData = {
    blockName: string;
    blocks?: ComponentData[];
    content?: any;
    __reference?: string;
    __missingRef?: boolean;
};

// Void OR an array of either a) JSXElements or b) functions that return JSXElements
type RenderComponentReturnType = void | (JSXElement | ((x: {}) => JSXElement))[];

const DynamicModuleGenerator = (props: DynamicModuleGeneratorProps) => {
    const renderComponent = (componentsData: ComponentData[] | undefined, parentProps: {}): RenderComponentReturnType => {
        if (!componentsData) {
            return;
        }

        const solidComponents = componentsData.map((componentData, index) => {
            const Module: Component = componentMap[componentData.blockName as keyof ComponentMap];

            if (!Module) {
                // backwards compability for when our php structure was a bit different (see more in form-selector.tsx)
                if (componentData.blockName === 'atos/forms') {
                    return null;
                }
                console.error('Could not match Gutenberg module of type "%s"', componentData.blockName); // eslint-disable-line no-console
                return <ErrorComponent error={{message: `No Solid-Component found for block ${componentData.blockName}`}} />;
            }

            // TODO: This is also here because of the form-selector component. I don't understand exactly why it causes so much problems,
            // there's something very wrong about how it was built and we don't have time to address it properly. In any case, this code
            // prevents the frontend from crashing if the editor simply saves to draft before actually picking an option in the dropdown, thereby
            // saving it without a proper ref.
            if (componentData.__missingRef) {
                return null;
            }
 
            const attributes = {...componentData};

            // TODO: This shouldnt be here, but it's a special case to handle form-selector block which has a reference to a form inside it.
            // If we delete blocks like below, the FormSelector component wont have atts.blocks that it needs to pass down to the Form component 
            if (!componentData.__reference) {
                delete attributes.blocks; // We dont want to pass the array of children, that goes as prop called children
            }

            // Combine all the props based on what the current module wants
            const combinedPassProps = convertAttsToProps(Module, attributes, parentProps);

            let children = componentData.blocks && componentData.blocks.length > 0 ? renderComponent(componentData.blocks, combinedPassProps) : null;

            if (children === null && componentData.content) {
                children = componentData.content;
            }

            const newProps = {
                children,
                key: index,
                itemIndex: index,
                ...combinedPassProps,
            };

            return createComponent(Module, {...newProps});
        });

        return solidComponents;
    };

    const getContent = () => {
        return convertToVirtualSections({
            content: props.content,
            skipSectionConversion: props.skipSectionConversion,
        });
    };

    const parentProps: ParentProps = {
        permalink: props.permalink,
        mobile: props.mobile,
        viewport: props.viewport
    };

    // Sorry, but it will complain without the `any` and i'm not sure why.
    const memo: any = createMemo(() => renderComponent(getContent(), parentProps));

    return (
        <>
            { memo() }
        </>
    );
};

export default DynamicModuleGenerator;
