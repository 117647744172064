import { styled } from 'solid-styled-components';
import theme from '../../style/theme';


export const StyledModalInner = styled.div<{ multipleVideos?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(p) => (!p.multipleVideos ? 'padding-inline: 3rem;' : '')}

    ${theme.mediaQueries.tabletMax} {
        padding-inline: 0;
        padding-top: 1rem;
    }
`;

export const StyledVideosContainerTablet = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
`;

export const StyledDotButton = styled.button<{ active: boolean }>`
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    background-color: ${(p) => (p.active ? theme.palette.darkGray : theme.palette.lightGray)};
    margin-inline: 0.5rem;
    cursor: pointer;
`;

export const StyledDotsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-block: 1rem;
    gap: 0.4rem;
`;
