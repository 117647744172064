import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../../style/theme';

const { spacing,palette } = theme;

export const StyledExpandableContainerContents = styled.div`
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    font-size: 1.43rem;
    color: ${palette.darkGray};
    text-transform: uppercase;

    background-color: ${palette.lightBeige};
    padding: ${spacing.m} ${spacing.xl};
    padding-top: 0;
    border-bottom-left-radius: 1.43rem;
    border-bottom-right-radius: 1.43rem;

    ${theme.mediaQueries.mobileMax} {
        font-size: 1.29rem;

        border-bottom-left-radius: 1.1rem;
        border-bottom-right-radius: 1.1rem;
        padding: ${spacing.xs};
    }
`;

