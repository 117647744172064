import { Show } from 'solid-js';
import { Forms } from '../forms/forms';

export const FormSelector = (props: any) => {

    // We keep backwards compability for when we didnt put the data within a "form" property in our php-structure
    // IF we are sure that all form-selector blocks has been re-saved, we can remove this.
    const isNewFormat = props?.form?.blocks?.length;

    return (
        <Show when={isNewFormat || props?.blocks?.length} fallback={<div />}>
            <Forms
                {...(isNewFormat
                    ? props?.form?.blocks?.[0]
                    : props?.blocks?.[0])}
                formCampaignId={isNewFormat ? props?.form?.formCampaignId : undefined}
            />
        </Show>
    );
};
