import { Match, Show, Switch, useContext } from 'solid-js';
import { Text } from '../../ui-components/text/text';
import DynamicModuleGenerator from '../../dynamic-module-generator';
import { Button } from '../../ui-components/button/button';
import { Heading } from '../../ui-components/heading/heading';
import { ImageComponent } from '../image-component/image-component';
import { FaqItemType } from './faq-item';
import { 
    StyledModalContainer,
    StyledModalHeader,
    StyledModalBody,
    StyledBlocksContainer,
    StyledMediaContainer,
    StyledLinksContainer,
} from './faq-content-modal.style';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { VideoIframeContainer } from '../../ui-components/video-iframe-container/video-iframe-container';

type FaqContentModalProps = {
    faqItem: FaqItemType;
    onClose: () => void;
    setLastTabbableElement: (el?: any) => void;
};

export const FaqContentModal = (props: FaqContentModalProps) => {
    const { localize, } = useContext(AppContext);
    
    return (
        <StyledModalContainer>
            <StyledModalHeader>
                <Text fontSize='small' noBlockSpacing>
                    {props.faqItem.category}
                </Text>
                <Heading tag='h3' variant='large'>
                    {props.faqItem.question}
                </Heading>
            </StyledModalHeader>

            <StyledModalBody>
                <StyledBlocksContainer>
                    <DynamicModuleGenerator
                        content={props.faqItem.blocks}
                        skipSectionConversion={true}
                    />
                </StyledBlocksContainer>
                <StyledMediaContainer>
                    <Switch>
                        <Match when={props.faqItem.mediaType === 'video'}>
                            <VideoIframeContainer videoLink={props.faqItem.video?.video_link} />
                        </Match>                        
                        <Match when={props.faqItem.mediaType === 'image'}>
                            <ImageComponent
                                src={props.faqItem!.image.url}
                                height={'AUTO'}
                                width={500}
                                altText={localize('faq_item_image', 'Alt Text for the image in the FAQ item')}
                                title={localize('faq_item_image', 'Alt Text for the image in the FAQ item')}
                                aspectRatio={'original'}
                                ratio={props.faqItem!.image.width / props.faqItem!.image.height}
                            />
                        </Match>
                    </Switch>
                </StyledMediaContainer>

            </StyledModalBody>

            <StyledLinksContainer>
                <Show when={props.faqItem.link1?.url}>
                    <Button
                        label={props.faqItem.link1?.title || ''}
                        url={props.faqItem!.link1!.url || ''}
                        variant='tertiary'
                        arrow={true}
                        isLink={true}
                        element={(el: HTMLElement) => {
                            // If there is no second link, set the last tabbable element to the first link
                            if (!props.faqItem.link2?.url) {
                                props.setLastTabbableElement(el);
                            }
                        }}
                    />
                </Show>
                <Show when={props.faqItem.link2?.url}>
                    <Button
                        label={props.faqItem.link2?.title || ''}
                        url={props.faqItem!.link2!.url || ''}
                        variant='tertiary'
                        arrow={true}
                        isLink={true}
                        element={(el: HTMLElement) => props.setLastTabbableElement(el)}
                    />
                </Show>
                <Show when={!(props.faqItem.link1?.url || props.faqItem.link2?.url)}>
                    {/* 
                        If there are no supplementary links, we need this placeholder button to set the last tabbable element.
                        Otherwise the last tab would take the user out of the modal.
                    */}
                    <Button 
                        element={(el: HTMLElement) => props.setLastTabbableElement(el)}
                        label={localize('close', 'Close')}
                        onClick={props.onClose}
                        variant='secondary'
                    />
                </Show>
            </StyledLinksContainer>

        </StyledModalContainer>
    );
};

