
import { createSignal, onMount, Show, useContext, For, Accessor } from 'solid-js';
import { Button } from '../../ui-components/button/button';
import { Text } from '../../ui-components/text/text';
import { FormProps, FieldType, FormValue, DropdownOption } from '../forms/forms.d';
import { FormWrapper, FormButtonWrapper, StyledTextArea, StyledPolicyWrapper, StyledFieldErrorMessage } from './forms.styles';
import { GraphQLClient, gql } from 'graphql-request';
import { countries } from '../../tools/countries-data';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { StyledFormInput, StyledFormSelect, StyledFormSelectWrapper, StyledIconWrapper } from '../registration-form/registration-form-styles';
import { validate } from './validation';
import { StyledLoadingSpinner } from '../loading-spinner/loading-spinner.styles';
import { useLocation, useSearchParams } from '@solidjs/router';
import { Checkbox } from '../../ui-components/inputs/checkbox/checkbox';
import { Radio } from '../../ui-components/inputs/radio/radio';
import { Link } from '../../ui-components/link/link';
import { StyledHorizontalSpace, StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { Heading } from '../../ui-components/heading/heading';
import { validateCaptcha } from '../../tools/validate-captcha';
import { StyledFlexColumn } from '../../ui-components/utility-style-components/flex';
import { AngleDownIcon } from '../icons-library/angle-down';
import { StyledErrorMessage } from '../login-form/login-form-styles';
import { EventContext } from '../event/event-context-provider';
import { atosGlobalCountrySlugToCountryCode } from '../../tools/countries-data';

const getUniqueSlug = (field: FieldType) => {
    if (field.extraSettings?.name?.value) {
        return field.extraSettings.name.value;
    }

    return field.slug;
    // We had this previously, but changing to use the name given in Gutenberg so we can pass the data to salesforce
    // with the correct value, matching what salesforce expects.
    // Not sure if this can cause any issues, so i'm keeping the below as a reference.
    // return `${field.slug}-${field.instance}`;
};

const client = new GraphQLClient('/api');

const POST_FORM_QUERY = gql`
    mutation PostForm (
        $type: String,
        $siteCountry: String,
        $input: salesforce_PostFormInput,
        $referer: String!
        $captchaToken: String!
        $gotoEventId: String
        $campaignId: String
    ) {
        postForm(
            type: $type,
            siteCountry: $siteCountry,
            input: $input,
            referer: $referer,
            captchaToken: $captchaToken,
            gotoEventId: $gotoEventId,
            campaignId: $campaignId,
        ) {
            success
        }
    }
`;


type Errors = Record<string, string>;


export const Forms = (props: FormProps) => {
    const AppState = useContext(AppContext);
    const { gotoEventId, isDisabledEvent } = useContext(EventContext);
    const { localize, privacyPolicyPage, recaptchaSiteKey, siteInfo, siteDomains, productContactFormUrl } = AppState;
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = createSignal(false);
    const [error, setError] = createSignal('');
    const [errors, setErrors] = createSignal<Errors>({});
    const [success, setSuccess] = createSignal('');

    onMount(() => {
        if (!document.querySelector('#grecaptcha')) {
            const scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.id = 'grecaptcha';
            scriptTag.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaSiteKey}`;
            document.head.appendChild(scriptTag);
        }
    });

    const filteredFields = () => siteInfo.siteType === 'atos-care' 
        ? props.fields.filter((field) => field.slug !== 'userType')
        : props.fields;

    const USER_TYPES = [
        // Keeping the values here exactly as Salesforce expects them, hence the uppercase first letter.
        {
            value: 'HCP',
            text: localize('i-am-a-healthcare-professional', 'I am a healthcare professional'),
        },
        {
            value: 'Patient',
            text: localize('i-am-a-patient', 'I am a patient'),
        },
        {
            value: 'Relative',
            text: localize('i-am-a-caregiver', 'I am a relative'),
        },
        {
            value: 'Other',
            text: localize('other', 'Other'),
        },
    ];

    const isBusinessLead = () => formValues().userType === 'HCP' || formValues().userType === 'Other';

    const setInitialValues = () => {
        const initialObj = filteredFields()?.reduce((obj: FormValue, field) => {
            const { slug } = field;
            if (slug === 'customDropdown' || slug === 'textArea' || slug === 'textField') {
                const uniqueSlug = getUniqueSlug(field);
                obj[uniqueSlug] = '';
            } else if (field.slug === 'hiddenField') {
                const uniqueSlug = getUniqueSlug(field);
                obj[uniqueSlug] = field.extraSettings?.fieldValue?.value;
            } else if (field.slug === 'country') {
                // Prepopulate the country field with the value that the site they're visiting has.
                if (siteInfo.siteSlug) {
                    const currentSelectCountryCode = atosGlobalCountrySlugToCountryCode[siteInfo.siteSlug as keyof typeof atosGlobalCountrySlugToCountryCode];
                    const currentSelectCountry = countries.find(country => country.code === currentSelectCountryCode);
                    if (currentSelectCountry) {
                        obj.country = currentSelectCountry;
                    }
                }
            } else {
                obj[field.slug] = '';
            }
            return obj;
        }, {});
        return initialObj;
    };

    const [formValues, setFormValues] = createSignal<FormValue>(setInitialValues());

    const handleInputChange = (fieldSlug: string, fieldValue: any, customInputSlug?: string) => {
        const newFormValues = { ...formValues() };
        if (fieldSlug === 'country') {
            // We want the whole country object here, not only the code.
            const country = countries.find(country => country.code === fieldValue);
            newFormValues[fieldSlug] = country;
        } else {
            newFormValues[fieldSlug] = fieldValue;
        }

        // If the form has errors, we want to remove it if it's fine now.
        const hasErrors = errors()[fieldSlug];

        setFormValues(newFormValues);

        if (hasErrors) {
            const field = !customInputSlug ? filteredFields().find((field) => field.slug === fieldSlug) : filteredFields().find((field) => field.slug === customInputSlug);
            if (field) {
                // Returns nothing, just sets signals so we're up to date.
                validateFields([field]);
            }
        }
    };

    // Eloqua expects these values to look like: {'checkboxGroupName': 'value1; value2; value3'}
    const handleCheckboxChange = (checkboxGroupName: string, checkboxValue: any, checked: boolean) => {
        const newFormValues = { ...formValues() };

        const checkboxValues = newFormValues[checkboxGroupName] ? newFormValues[checkboxGroupName].split(';') : [];
        if (checked) {
            checkboxValues.push(checkboxValue);
        } else {
            const index = checkboxValues.indexOf(checkboxValue);
            checkboxValues.splice(index, 1);
        }
        const checkboxValueString = checkboxValues.join(';');
        newFormValues[checkboxGroupName] = checkboxValueString;

        setFormValues(newFormValues);

        // If the checkbox has errors, we want to remove it if it's fine now.
        const hasErrors = errors()[checkboxGroupName];

        if (hasErrors) {
            const field = filteredFields().find((field) => field.extraSettings?.name?.value === checkboxGroupName);
            if (field) {
                // Returns nothing, just sets signals so we're up to date.
                validateFields([field]);
            }
        }
    };

    const handleRadioButtonChange = (radioButtonGroupName: string, value: string) => {
        setFormValues({ ...formValues(), [radioButtonGroupName]: value });

        // If the radiobutton had errors, we want to remove it if it's fine now.
        const hasErrors = errors()[radioButtonGroupName];

        if (hasErrors) {
            const field = filteredFields().find((field) => field.extraSettings?.name?.value === radioButtonGroupName);
            if (field) {
                // Returns nothing, just sets signals so we're up to date.
                validateFields([field]);
            }
        }
    };

    const handleDropdownChange = (dropdownGroupName: string, value: string) => {
        setFormValues({ ...formValues(), [dropdownGroupName]: value });

        // If the dropdown had errors, we want to remove it if it's fine now.
        const hasErrors = errors()[dropdownGroupName];

        if (hasErrors) {
            const field = filteredFields().find((field) => field.extraSettings?.name?.value === dropdownGroupName);
            if (field) {
                // Returns nothing, just sets signals so we're up to date.
                validateFields([field]);
            }
        }
    };


    const handleCheck = (fieldSlug: string) => {
        const newFormValues = { ...formValues() };
        newFormValues[fieldSlug] = !newFormValues[fieldSlug];
        setFormValues(newFormValues);
        const hasErrors = errors()[fieldSlug];
        if (hasErrors) {
            const field = filteredFields().find((field) => field.slug === fieldSlug);
            if (field) {
                // Returns nothing, just sets signals so we're up to date.
                validateFields([field]);
            }
        }
    };

    const handleSend = async () => {
        setLoading(true);
        setErrors({});

        const extraFields = [];
        if (isBusinessLead()) {
            extraFields.push({
                name: 'Company',
                slug: 'company',
                required: true,
            });
        }
        const isFormValid = validateFields([...filteredFields(), ...extraFields]);
        if (!isFormValid) {
            setLoading(false);
            return;
        }

        try {
            const captchaToken = await validateCaptcha('submit_form', recaptchaSiteKey);            

            if (!captchaToken) {
                throw new Error('Captcha validation failed - probably a bot.');
            }

            const currentSite = siteDomains.find((domain) => domain.siteId === siteInfo.siteId);
            const productForm = productContactFormUrl?.includes(location.pathname);

            let formType;
            if (siteInfo.siteType === 'atos-care') {
                formType = 'contactAtosCare';
            } else {
                formType = 'contact';

                if (productForm) {
                    formType = 'product';
                }
                if (gotoEventId) {
                    formType = 'goto-event';
                }
            }

            const formAnswers = { ...formValues() };
            delete formAnswers.acceptPolicy;

            const variables = {
                type: formType,
                referer: window.location.href,
                siteCountry: currentSite?.country || '',
                captchaToken,
                gotoEventId,
                input: {
                    formData: {
                        ...formAnswers,
                        ...searchParams?.product && { leadHook: searchParams?.product }, // If we have a product in the url, we add it to the form data as a lead hook.
                    },
                },
                campaignId : props.formCampaignId,
            };

            const res = await client.request(POST_FORM_QUERY, variables);

            if (res.postForm.success) {
                setSuccess(localize('form-submit-success', 'Form submitted'));
                setFormValues(setInitialValues());
            } else {
                throw new Error();
            }
        } catch (e) {
            console.log('Error submitting form: ', e);
            setError(localize('form-submit-error', 'Error submitting the form'));
        }

        setLoading(false);
    };


    const validateFields = (fields: FieldType[]) => {
        fields.forEach((field) => {
            const { slug } = field;
            if (slug === 'acceptPolicy') {
                const checked = formValues()['acceptPolicy'];
                if (!checked) {
                    const msg = `${localize('please-agree-to-terms', 'Please agree to the terms and conditions before submitting')}`;
                    setErrors({ ...errors(), [field.slug]: msg });
                    return;
                } else {
                    const currentErrors = { ...errors() };
                    delete currentErrors['acceptPolicy'];
                    setErrors(currentErrors);
                }
                // return;
            } else if (slug === 'customCheckbox' || slug === 'customDropdown' || slug === 'customRadioButtons' || slug === 'customTextField' || slug === 'textArea') {
                const { extraSettings, required } = field;
                if (!extraSettings) return;
                const { name } = extraSettings;
                const value = formValues()[name.value];
                if (required && (!value || value.length === 0)) {
                    let fieldName = '';
                    if (field.slug === 'customCheckbox') {
                        fieldName = 'Checkbox';
                    } else if (field.slug === 'customDropdown') {
                        fieldName = 'Dropdown';
                    } else if (field.slug === 'customRadioButtons') {
                        fieldName = 'Radio Button';
                    } else if (field.slug === 'customTextField') {
                        fieldName = 'Textfield';
                    } else if (field.slug === 'textArea') {
                        fieldName = 'Textarea';
                    }
                    const msg = `${localize(field.slug, fieldName)} ${localize('is-required', 'is required')}`;
                    setErrors({ ...errors(), [name.value]: msg });
                } else {
                    const currentErrors = { ...errors() };
                    delete currentErrors[name.value];
                    setErrors(currentErrors);
                }
            } else {
                const key = field.instance ? `${field.slug}-${field.instance}` : field.slug;
                const value = formValues()[key];
                if (field.required && (!value || (typeof value === 'string' && !value.trim()))) {
                    const msg = `${localize(field.slug, field.name)} ${localize('is-required', 'is required')}`;
                    setErrors({ ...errors(), [field.slug]: msg });
                    return false;
                }

                // Not required but no value is fine.
                if (!value) {
                    return;
                }

                // If we have a value, we validate it.
                const { valid, errorMessage } = validate(key, value, localize);
                if (errorMessage) {
                    setErrors({ ...errors(), [field.slug]: errorMessage });
                } else if (valid) {
                    const currentErrors = { ...errors() };
                    delete currentErrors[field.slug];
                    setErrors(currentErrors);
                }
            }
        });

        if (Object.keys(errors()).length > 0) {
            return false;
        }

        return true;
    };

    const renderInput = (args: { field: FieldType; forceRequire?: Accessor<boolean>; }) => {
        const { field, forceRequire } = args;

        let translationSlug = field.slug;

        switch (field.slug) {
            case 'firstname':
                translationSlug = 'first-name';
                break;
            case 'lastname':
                translationSlug = 'last-name';
                break;
            case 'zip':
                translationSlug = 'postal-code';
                break;
            default:
                break;
        }

        return (
            <>
                <StyledFormInput
                    required={field.required || (forceRequire && forceRequire())}
                    value={formValues()[field.slug]}
                    onChange={(e) => handleInputChange(field.slug, e.currentTarget.value)}
                    placeholder={`${localize(translationSlug, field.name)}${field.required || (forceRequire && forceRequire()) ? '*' : ''}`}
                    hasErrors={errors()[field.slug] ? true : false}
                    name={field.slug}
                />
                <Show when={errors()[field.slug]}>
                    <StyledFieldErrorMessage innerHTML={errors()[field.slug]} />
                </Show>
            </>
        );
    };

    // Very similar to renderInput, but this comes from a custom text field in WP, and the slug/name/eloqua-value 
    // should not be the slug of the field, but instead a value coming from WP, written by the editors.
    const renderTextField = (field: FieldType) => {
        const { extraSettings } = field;
        if (!extraSettings) return;

        const placeholder = field.extraSettings?.placeholder?.value || localize('text-field', 'Text field');

        const { name } = extraSettings;
        return (
            <>
                <StyledFormInput
                    name={'custom-text-field-' + name.value}
                    required={field.required}
                    value={formValues()[name.value]}
                    onChange={((e) => handleInputChange(name.value, e.currentTarget.value, field.slug))}
                    placeholder={placeholder}
                    hasErrors={errors()[name.value] ? true : false}
                />
                <Show when={(errors()[name.value])}> 
                    <StyledFieldErrorMessage innerHTML={errors()[name.value]} />
                </Show>
            </>
        );

    };

    const renderDropdown = (field: FieldType) => {
        const { extraSettings, required } = field;

        if (!extraSettings) return;
        const { info, placeholder, name, options } = extraSettings;

        // Backwards compability for when the dropdown options were coming from info.value (different WP setup).
        const optionsArray = options?.value ? options.value.split('\n') : info.value.split(',');
        const formatedOptions: DropdownOption[] = optionsArray.map((v: string) => v.trim())
            .map((v: string) => {
                const label = v.charAt(0).toUpperCase() + v.slice(1);
                return {
                    value: v,
                    text: label,
                };
            }).filter((option: DropdownOption) => option.value);
        return (
            <>
                <StyledFormSelectWrapper>
                    <StyledFormSelect
                        required={required}
                        value={formValues()[name.value]}
                        hasErrors={errors()[name.value] ? true : false}
                        onChange={((e) => handleDropdownChange(name.value, e.currentTarget.value))}
                        name={'custom-dropdown-' + name.value}
                    >
                        {field.required ?
                            <option value="">{placeholder.value + '*'}</option>
                            :
                            <option value="">{placeholder.value}</option>
                        }
                        {
                            formatedOptions.map((option) => {
                                return (
                                    <option value={option.value}>{option.text}</option>
                                );
                            })
                        }
                    </StyledFormSelect>
                    <StyledIconWrapper>
                        <AngleDownIcon height={1.8} width={1.8} min-width="1.2" min-height="1.2" fill="#707070" />
                    </StyledIconWrapper>
                </StyledFormSelectWrapper>
                <Show when={(errors()[name.value])}>
                    <StyledFieldErrorMessage innerHTML={errors()[name.value]} />
                </Show>
            </>);
    };


    const renderCheckbox = (field: FieldType) => {
        const { required, extraSettings } = field;

        if (!extraSettings) return;

        const { options: checkboxOptions, name } = extraSettings;
        const placeholder = required ? extraSettings.placeholder.value + '*' : extraSettings.placeholder.value;
        const options: DropdownOption[] = checkboxOptions.value
            .split('\n')
            .map((v: string) => v.trim())
            .map((v: string) => {
                const label = v.charAt(0).toUpperCase() + v.slice(1);
                return {
                    value: v,
                    text: label,
                };
            })
            .filter((option: DropdownOption) => option.value);

        return (
            <>
                <Heading tag="h4" variant="tinyGray" noBlockSpacing={true}>{placeholder}</Heading>
                <ul>
                    <For each={options}>
                        {(option, i) => {
                            return (
                                <li>
                                    <Checkbox
                                        isChecked={false}
                                        whenClicked={(e: any) => handleCheckboxChange(name.value, option.value, e.target.checked)}
                                        value={option.value}
                                        name={option.text}
                                        inputName={'custom-checkbox-' + name.value}
                                        id={`checkbox-${option.value}-${i()}`}
                                    />
                                </li>
                            );
                        }}
                    </For>
                </ul>
                <Show when={(errors()[name.value])}> 
                    <StyledFieldErrorMessage innerHTML={errors()[name.value]} />
                </Show>
            </>
        );
    };


    const renderTextArea = (field: FieldType) => {
        const placeholder = field?.extraSettings?.placeholder.value || localize('text-area', 'Text area');

        const uniqueSlug = getUniqueSlug(field);

        return (
            <StyledTextArea
                required={field.required}
                value={formValues()[uniqueSlug]}
                onChange={((e) => handleInputChange(uniqueSlug, e.currentTarget.value, field.slug))}
                placeholder={placeholder}
                name={'custom-text-area-' + field.slug}
                rows={6}
                hasErrors={errors()[uniqueSlug] ? true : false}
            />
        );
    };

    const renderComment = (field: FieldType) => {
        const uniqueSlug = getUniqueSlug(field);

        return (
            <StyledTextArea
                required={field.required}
                value={formValues()[uniqueSlug]}
                onChange={((e) => handleInputChange(uniqueSlug, e.currentTarget.value))}
                placeholder={`${localize('comments', 'Comments')}${field.required ? '*' : ''}`}
                name={field.slug}
                rows={1}
                hasErrors={errors()[field.slug] ? true : false}
            />
        );
    };

    const renderHiddenField = () => {
        const hiddenFields = filteredFields().filter(f => f.slug === 'hiddenField');
        if (hiddenFields && hiddenFields.length) {
            return hiddenFields.map((fieldData) => {
                const uniqueSlug = getUniqueSlug(fieldData);
                return (
                    <input
                        name={uniqueSlug}
                        hidden
                    />

                );
            });
        }
    };

    const renderRadioButtons = (field: FieldType) => {
        const { extraSettings, required } = field;

        if (!extraSettings) return;

        const { options: radioButtonOptions, name } = extraSettings;
        const placeholder = required ? extraSettings.placeholder.value + '*' : extraSettings.placeholder.value;
        const options: DropdownOption[] = radioButtonOptions.value
            .split('\n')
            .map((v: string) => v.trim())
            .map((v: string) => {
                const label = v.charAt(0).toUpperCase() + v.slice(1);
                return {
                    value: v,
                    text: label,
                };
            })
            .filter((option: DropdownOption) => option.value);
        return (
            <>
                <Heading tag="h4" variant="tinyGray" noBlockSpacing={true}>{placeholder}</Heading>
                <ul>
                    <For each={options}>
                        {(option: { value: string; text: string }, i) => (
                            <li>
                                <Radio
                                    whenClicked={(() => handleRadioButtonChange(name.value, option.value))}
                                    value={option.value}
                                    readableName={option.text}
                                    isChecked={false}
                                    name={'custom-radio-' + name.value}
                                    id={`radio-${option.value}-${i()}`}
                                />
                            </li>
                        )}
                    </For>
                </ul>
                <Show when={(errors()[name.value])}> 
                    <StyledFieldErrorMessage innerHTML={errors()[name.value]} />
                </Show>
            </>
        );
    };

    const renderCountry = (field: FieldType) => {
        const title = `${localize('country', 'Country')}${field.required ? '*' : ''}`;

        const options: DropdownOption[] = countries.map((country) => {
            return {
                value: country.code,
                text: country.name,
            };
        });

        const currentSelectCountryCode = atosGlobalCountrySlugToCountryCode[siteInfo.siteSlug as keyof typeof atosGlobalCountrySlugToCountryCode];
        const countryHtml = (
            <>
                <StyledFormSelectWrapper>
                    <StyledFormSelect
                        name={field.slug}
                        required={field.required}
                        value={formValues().country?.code}
                        hasErrors={errors()[field.slug] ? true : false}
                        onChange={(e) => handleInputChange(field.slug, e.currentTarget.value)}
                    >
                        <option value="">{title}</option>
                        {options.map((option) => {
                            return <option selected={option.value === currentSelectCountryCode} value={option.value}>{option.text}</option>;
                        })}
                    </StyledFormSelect>
                    <StyledIconWrapper>
                        <AngleDownIcon height={1.8} width={1.8} min-width="1.2" min-height="1.2" fill="#707070" />
                    </StyledIconWrapper>
                </StyledFormSelectWrapper>
                <Show when={errors()[field.slug]}>
                    <StyledFieldErrorMessage innerHTML={errors()[field.slug]} />
                </Show>
            </>
        );

        return countryHtml;
    };


    const renderUserType = (field: FieldType) => {
        const options: DropdownOption[] = USER_TYPES;
        const placeholder = `${localize('user-type', 'User type')}${field.required ? '*' : ''}`;

        const userTypeHtml = (
            <>
                <StyledFormSelectWrapper>
                    <StyledFormSelect
                        name={field.slug}
                        required={field.required}
                        value={formValues()[field.slug]}
                        hasErrors={errors()[field.slug] ? true : false}
                        onChange={((e) => handleInputChange(field.slug, e.currentTarget.value))}>
                        <option value="">{placeholder}</option>
                        {
                            options.map((option) => {
                                return (
                                    <option value={option.value}>{option.text}</option>
                                );
                            })
                        }
                    </StyledFormSelect>
                    <StyledIconWrapper>
                        <AngleDownIcon height={1.8} width={1.8} min-width="1.2" min-height="1.2" fill="#707070" />
                    </StyledIconWrapper>
                </StyledFormSelectWrapper>
                <Show when={(errors()[field.slug])}>
                    <StyledFieldErrorMessage innerHTML={errors()[field.slug]} />
                </Show>
            </>
        );

        return userTypeHtml;
    };


    const renderCommunicationPreference = (field: FieldType) => {
        const placeholder = `${localize('communication-preference', 'Communication preference')}${field.required ? '*' : ''}`;
        return (
            <StyledFormSelectWrapper>
                <StyledFormSelect
                    name={field.slug}
                    required={field.required}
                    value={formValues()[field.slug]}
                    hasErrors={errors()[field.slug] ? true : false}
                    onChange={((e) => handleInputChange(field.slug, e.currentTarget.value))}>
                    <option value="">{placeholder}</option>
                    <option value={'Phone'}>{localize('phone', 'Phone')}</option>
                    <option value={'Email'}>{localize('email', 'E-mail')}</option>
                </StyledFormSelect>
                <StyledIconWrapper>
                    <AngleDownIcon height={1.8} width={1.8} min-width="1.2" min-height="1.2" fill="#707070" />
                </StyledIconWrapper>
                <Show when={(errors()[field.slug])}> 
                    <StyledFieldErrorMessage innerHTML={errors()[field.slug]} />
                </Show>
            </StyledFormSelectWrapper>
        );
    };


    // This should always be at the bottom, for now at least.
    const renderPolicy = () => {
        const policy = filteredFields().find(f => f.slug === 'acceptPolicy');
        if (policy) {
            return (
                <>
                    <StyledPolicyWrapper>
                        <Checkbox
                            isChecked={formValues()?.acceptPolicy}
                            whenClicked={() => {
                                handleCheck(policy.slug);
                            }}
                            value="privacy-policy-consent"
                            name={localize('agree-to-terms', 'I agree to the terms and conditions')}
                        />
                        <Link noBlockSpacing={true} label={localize('read-more-here', 'Read more here')} url={privacyPolicyPage || '/'} />
                    </StyledPolicyWrapper>
                    <Show when={errors()[policy.slug]}>
                        <StyledFieldErrorMessage innerHTML={errors()[policy.slug]} />
                    </Show>
                </>
            );
        } else {
            return null;
        }
    };

    const renderCompanyField = () => {
        // If user selects HCP or Other (i.e. a "business lead"), the company field should be required/mandatory.
        // However, if it's not even added in the WP form we need to manually add it down here.
        if (!isBusinessLead()) return null;

        const formHasCompanyField = filteredFields().some((field: FieldType) => {
            return field.slug === 'company';
        });

        if (!formHasCompanyField) {
            const field = {
                name: 'Company',
                slug: 'company',
                required: true,
            };

            const renderedCompanyField = renderInput({field, forceRequire: () => true});
            return renderedCompanyField;
        }
    };

    const productContactTitle = () => {
        // This ensures that the pre- and post-text are only added if they are not empty, taking into account required spaces.
        const preTextTranslationExists = localize('product-contact-form-pre-text', '') !== '';
        const preText = preTextTranslationExists ? `${localize('product-contact-form-pre-text', '')} ` : '';
        const postText = localize('product-contact-form-post-text', '');

        return `${preText + searchParams?.product} ${postText}`;
    };

    return (
        <ErrorCatcher componentName='Forms'>
            {/* Only render the form if the privacy policy page is set and the fields are loaded */}
            <Show when={privacyPolicyPage && filteredFields() && !isDisabledEvent }>
                <Show when={searchParams?.product}>
                    <Text noBlockSpacing={false} fontSize='normal' fontStyle='italic' color='darkestGray' displayRedVerticalLine={false}>
                        {productContactTitle()}
                    </Text>
                </Show>
                <FormWrapper>
                    <For each={filteredFields()}>{(field: FieldType) => {
                        const { slug } = field;

                        // We don't want to render the policy field here, as it's rendered separately.
                        if (slug === 'acceptPolicy') {
                            return null;
                        }

                        // Handle the special cases first.
                        if (slug === 'company') {
                            return renderInput({ field, forceRequire: isBusinessLead });
                        }

                        const slugFunctionMap = {
                            'country': renderCountry,
                            'hiddenField': renderHiddenField,
                            'userType': renderUserType,
                            'comments': renderComment,
                            'communicationPreference': renderCommunicationPreference,
                            'customDropdown': renderDropdown,
                            'customTextField': renderTextField,
                            'customRadioButtons': renderRadioButtons,
                            'customCheckbox': renderCheckbox,
                            'textArea': renderTextArea,
                        };
                        
                        if (Object.keys(slugFunctionMap).includes(slug)){
                            return slugFunctionMap[slug as keyof typeof slugFunctionMap](field);
                        }
                        
                        // Default to text input field.
                        return renderInput({ field });
                    }}</For>

                    {renderCompanyField()}

                    <Text noBlockSpacing={true} fontSize='small' fontStyle='italic' color='darkGray'>{`*${localize('required-fields', 'required fields')}`}</Text>

                    <StyledVerticalSpace size={0.5} />

                    {renderPolicy()}

                    <Show when={error()} fallback={<div style={{ height: '22px' }}></div>}>
                        <StyledErrorMessage>{error()}</StyledErrorMessage>
                    </Show>

                    <Show when={loading()}>
                        <StyledLoadingSpinner />
                    </Show>

                    <Show when={success()}>
                        <Heading tag='h4' variant='smallGray'>
                            {success()}
                        </Heading>
                    </Show>
                    <Show when={!success()}>
                        <FormButtonWrapper>
                            <Button label={localize('form-submit', 'Submit')} onClick={handleSend} />
                        </FormButtonWrapper>
                        <Show when={Object.keys(errors()).length > 0}>
                            <Text fontSize="small" color='pink'>{localize('the-form-has-errors', 'The Form Has Errors')}</Text>
                        </Show>
                        <StyledFlexColumn>
                            <Text fontSize='small' fontStyle='italic' color='darkGray' noBlockSpacing={true}>
                                {localize('recaptcha-policy-and-terms-disclaimer', 'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.')}
                            </Text>
                            <StyledHorizontalSpace size={1} />
                            <Text fontSize='small' fontStyle='italic' color='darkGray'>
                                {`<a href="https://policies.google.com/privacy">${localize('recaptcha-privacy-policy', 'Privacy Policy')}</a> | <a href="https://policies.google.com/terms">${localize('recaptcha-terms-of-service', 'Terms of Service')}</a>`}
                            </Text>
                        </StyledFlexColumn>
                    </Show>
                </FormWrapper>
            </Show>
        </ErrorCatcher>
    );
};

Forms.parseProps = (atts: any) => {
    const { fields } = atts;
    const commonAtss = {
        fields,
    };

    return commonAtss;
};
