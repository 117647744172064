import { useContext } from 'solid-js';
import { AppContext } from '../../../app-context-provider/app-context-provider';
import theme from '../../../style/theme';
import { ExpandableContainer } from '../../../ui-components/expandable-container/expandable-container';
import { StyledExpandableContainerContents } from './expandable-product-container.styles';


export const ExpandableProductContainer = (props: { heading: string; children: any; initialOpen?: boolean }) => {
    const { viewport } = useContext(AppContext);

    const isMobile = () => viewport.width <= theme.breakpoints.MOBILE;

    return (
        <ExpandableContainer
            initialOpen={props.initialOpen}
            chevronColor={'red'}
            chevronSize={isMobile() ? 1.5 : 2.14}
            roundCorners={true}
            backgroundColor={theme.palette.lightBeige}
            withDividerLine={true}
            headingProps={{
                noBlockSpacing: true,
                tag: 'h4',
                variant: 'small',
                children: props.heading,
            }}
            customHeadingRowCss={`
                background-color: ${theme.palette.lightBeige};
                padding: ${theme.spacing.s} ${theme.spacing.xl};
                border-top-left-radius: ${theme.borderRadius.medium};
                border-top-right-radius: ${theme.borderRadius.medium};
                padding-inline: ${isMobile() ? theme.spacing.xs : theme.spacing.xl};
            `}
        >
            <StyledExpandableContainerContents>
                {props.children}
            </StyledExpandableContainerContents>
        </ExpandableContainer>
    );
};
