import { createEffect, createSignal, on, Show } from 'solid-js';
import { StyledVideoContainer } from './video-iframe-container.style';
import { getYoutubeVideoId, getVimeoUrl } from '../../components/video-embed/helpers';

type VideoIframeContainerProps = {
    videoLink?: string;
};

export const VideoIframeContainer = (props: VideoIframeContainerProps) => {
    const [ isLoading, setIsLoading ] = createSignal(false);

    createEffect(() => {
        setIsLoading(true);
    });

    createEffect(
        on(
            () => props.videoLink,
            () => {                
                setIsLoading(false);
                setTimeout(() => {
                    setIsLoading(true);
                }, 10);
            }
        )
    );

    return (
        <Show when={props.videoLink}>
            <StyledVideoContainer isLoading={isLoading()}>
                <Show when={props.videoLink?.includes('youtu')}>
                    <iframe
                        src={'https://www.youtube-nocookie.com/embed/' + getYoutubeVideoId(props.videoLink!)}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </Show>
                <Show when={props.videoLink?.includes('vimeo')}>
                    <iframe
                        src={getVimeoUrl(props.videoLink!)}
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </Show>
            </StyledVideoContainer>
        </Show>
    );
};

