import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

type StyledExpandableHeadingRowProps = {
    backgroundColor?: string;
    customHeadingRowCss?: string;
    isOpen?: boolean;
    withBorder?: boolean;
    roundCorners?: boolean;
    disableClick?: boolean;
    withPadding?: boolean;
    isDropdown?: boolean;
}

type StyledExpandableContainerProps = {
    isDropdown: boolean;
    isOpen: boolean;
    withBorder?: boolean;
}

export const StyledExpandableWrapper = styled.div<{ isDropdown: boolean; shrinkOnTablet?: boolean }>`
    width: 100%;
    position: relative;
    top: 0;
    ${p => p.isDropdown ? 'height: 7.3rem;' : ''}

    ${p => p.isDropdown && p.shrinkOnTablet ? `
        ${theme.mediaQueries.tabletMaxOriginal} {
            height: 4rem;
        }
    ` : ''}
`;

export const StyledExpandableContainer = styled.div<StyledExpandableContainerProps>`
    ${p => p.isDropdown ? `
        padding-inline: 1.5rem;
        position: absolute;
        right: 0;
        left: 0;
        border-radius: ${theme.borderRadius.small};
        ${p.withBorder ? `border: 1px solid ${theme.palette.darkGray};` : ''}
        z-index: 2;
        background-color: white;
        transition: box-shadow 0.2s ease-in-out, padding-bottom 0.2s ease-in-out;

        &:hover {
            box-shadow: 0px 0.41rem 0.62rem rgba(0,0,0,0.1);
        }

        ${p.isOpen ? `
            box-shadow: 0px 0.21rem 0.62rem rgba(0,0,0,0.19);
        ` : ''}

        ${theme.mediaQueries.mobileMax} {
            ${p.withBorder && p.isOpen ? 'padding-bottom: 1.2rem;' : ''}
        }
    ` : ''}
`;

const styledExpandableHeadingRowStyle = (p: StyledExpandableHeadingRowProps) => `
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border-bottom-left-radius 0.2s ease-in-out, border-bottom-right-radius 0.2s ease-in-out;

    ${!p.disableClick ? 'cursor: pointer;' : ''}
    ${p.backgroundColor ? `background-color: ${p.backgroundColor};` : ''}
    ${p.withPadding ? 'padding-block: 1.5rem;' : 'padding-bottom: 0.5rem;'}

    border-top-left-radius: ${p.roundCorners ? theme.borderRadius.medium : 0};
    border-top-right-radius: ${p.roundCorners ? theme.borderRadius.medium : 0};
    border-bottom-left-radius: ${p.roundCorners && !p.isOpen ? theme.borderRadius.medium : 0};
    border-bottom-right-radius: ${p.roundCorners && !p.isOpen ? theme.borderRadius.medium : 0};

    ${theme.mediaQueries.mobileMax} {
        ${p.withBorder ? 'padding-block: 1.5rem;' : 'padding-bottom: 0.5rem;'}

        border-top-left-radius: ${p.roundCorners ? theme.borderRadius.small : 0};
        border-top-right-radius: ${p.roundCorners ? theme.borderRadius.small : 0};
        border-bottom-left-radius: ${p.roundCorners && !p.isOpen ? theme.borderRadius.small : 0};
        border-bottom-right-radius: ${p.roundCorners && !p.isOpen ? theme.borderRadius.small : 0};
        
        ${p.customHeadingRowCss ? p.customHeadingRowCss : ''}
    }

    ${p.customHeadingRowCss ? p.customHeadingRowCss : ''}
`;

export const StyledExpandableHeadingRowButton = styled.button<StyledExpandableHeadingRowProps>`
    ${p => styledExpandableHeadingRowStyle(p)}
`;

export const StyledExpandableHeadingRowDiv = styled.div<StyledExpandableHeadingRowProps>`
    ${p => styledExpandableHeadingRowStyle(p)}
`;

export const StyledChildrenContainer = styled.div<{ withDividerLine?: boolean }>`
    position: relative;

    ${p => p.withDividerLine ? `
        &::after {
            position: absolute;
            content: '';
            display: block;
            clear: both;
            left: 3rem;
            right: 3rem;
            height: 2px;
            background-color: ${theme.palette.darkGray};
            top: -1.5rem;
        
            ${theme.mediaQueries.mobileMax} {
                height: 1px;
                top: -1rem;
                left: 1.5rem;
                right: 1.5rem;
            }
        }
    ` : ''}
`;

