import { styled } from 'solid-styled-components';
import theme, {fontFamilyAtosBold} from '../../style/theme';
import { StyledDocumentTypeFlagTextSmallProps } from './document-type-flag-types';

export const StyledDocumentTypeFlagText = styled.span`
    font-size: 2.07rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: capitalize;
    color: ${theme.palette.white};
    position: absolute;
    top: 0.93rem;
    left: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 7.14rem;
`;

const largeHeight = 3.5;

export const StyledDocumentTypeFlag = styled.div<{ displaySide?: 'left' | 'right', size?: 'large' }>`
    position: absolute;
    ${p => p.displaySide === 'left' ? 'left: -36px;' : 'right: -34px;'}
    top: 1.7rem;
    height: ${p => p.size === 'large' ? largeHeight + 'rem' : '2.8rem'};
    z-index: 2;
    display: flex;
    pointer-events: none;
`;

const sharedDocumentTypeFlagText = (p: any) => `
    font-size: 1.5rem;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: capitalize;
    color: ${theme.palette.white};
    background: ${p.backgroundColor};
    border: 1px solid white;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    transform: translateX(1px);

`;

export const StyledDocumentTypeFlagTextSmall = styled.div<StyledDocumentTypeFlagTextSmallProps>`
    ${p => sharedDocumentTypeFlagText(p)}

    ${p => p.displaySide === 'left' ? `
        padding-left: 0.4rem;
        padding-right: 0.8rem;
        border-radius: 0 1rem 1rem 0;
        border-left: none;
        ` : `
        padding-left: 0.8rem;
        padding-right: 0.4rem;
        border-radius: 1rem 0 0 1rem;
        border-right: none;

    `}
`;

export const StyledDocumentTypeFlagTextLarge = styled.div<StyledDocumentTypeFlagTextSmallProps>`
    ${p => sharedDocumentTypeFlagText(p)}

    ${p => p.displaySide === 'left' ? `
        padding-left: 0.8rem;
        padding-right: 2.5rem;
        border-radius: 0 3rem 3rem 0;
        border-left: none;
        ` : `
        padding-left: 2.5rem;
        padding-right: 1rem;
        border-radius: 3rem 0 0 3rem;
        border-right: none;
    `}
`;

export const StyledIconPlaceholder = styled.div<{ size?: 'large' }>`
    width: 3rem;
    svg {
        height: ${p => p.size === 'large' ? largeHeight * 1.5 + 'rem' : '4.17rem'};
    }
`;
