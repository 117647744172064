import { DividerComponentProps } from './divider-types';
import { StyledDivider } from './divider.styles';

export const Divider = (props: DividerComponentProps) => {
    return (
        <StyledDivider
            color={props.color}
            marginSizeTop={props.marginSizeTop}
            marginSizeBottom={props.marginSizeBottom}
            manualWidth={props.manualWidth}
            width={props.width}
            isTransparent={props.isTransparent}
            forPdfBuilder={props.forPdfBuilder}
        />
    );
};

Divider.parseProps = (atts: any) => {
    return atts;
};
