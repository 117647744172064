import { Show, mergeProps, onMount, useContext } from 'solid-js';

import { css } from 'solid-styled-components';
import { AppContext } from '../../app-context-provider/app-context-provider';
import IntersectionObserver from '../intersection-observer/intersection-observer';

import { ErrorCatcher } from '../../tools/error-catcher';
import { ImageWordpressBlock } from '../../types/shared';
import {
    ImageWrapper,
    StyledFigure,
    imageFullresCss,
    imageFullresLoadedCss,
    imageLowresCss,
    imageLowresTransitionEndedCss,
    imagePictureCss,
} from './image-component.styles';
import { StyledVerticalSpace } from '../../ui-components/utility-style-components/spacing';
import { Focus } from '../../tools/get-image-focus';

type ImageComponentProps = {
    src: string;
    imageText?: string;
    altText: string;
    title: string;
    width?: number | string;
    height?: number | string;
    sign?: string;
    ratio?: number;
    threshold?: number;
    focus?: {
        y: number;
        x: number;
    };
    aspectRatio?: 'original' | 'wide' | 'extraWide' | 'portrait' | 'standard' | 'square' | 'circle';
    roundCorners?: boolean;
    spacer?: boolean;
    forPdfBuilder?: boolean;
    gutenberg?: boolean;
};

type SupportedImageFormatsType = {
    webp: boolean;
    avif: boolean;
};

type GetImageServiceSrcPathProps = {
    imagesServiceUrl: string;
    supportedImageFormats: SupportedImageFormatsType;
    src: string;
    sign?: string;
    width: string | number;
    height: string | number;
    focus?: Focus;
    jwt?: string;
};

export const getImageServiceSrcPath = (props: GetImageServiceSrcPathProps) => {
    const host = props.imagesServiceUrl + '/image';
    let parsedSrc = props.src;

    if (!parsedSrc.match(/\.png/) && props.supportedImageFormats.avif && !parsedSrc.match(/\.avif$/)) {
        parsedSrc += '.avif';
    } else if (props.supportedImageFormats.webp && !parsedSrc.match(/\.webp/)) {
        parsedSrc += '.webp';
    }

    let imageUrl = `${host}/${props.width}x${props.height}${parsedSrc || '/'}`;

    if (props.focus) {
        imageUrl += `?focus=${props.focus.x},${props.focus.y}`;
    }
    if (props.sign) {
        imageUrl += `${imageUrl.includes('?') ? '&' : '?'}sign=${props.sign}`;
    }

    return imageUrl || '';
};

export type ImageSrcData = {
    original: string;
    retina: string;
    jwt?: string;
};

export const getBackgroundImageSrcData = (props: GetImageServiceSrcPathProps): ImageSrcData => {
    const originalWidthToFetch = getImageWidthToFetch(props.width, false);
    const retinaWidthToFetch = getImageWidthToFetch(props.width, true);

    const originalPath = getImageServiceSrcPath({ ...props, width: originalWidthToFetch });
    const retinaPath = getImageServiceSrcPath({ ...props, width: retinaWidthToFetch });

    return {
        original: originalPath,
        retina: retinaPath,
    };
};

const getImageWidthToFetch = (width: string | number | undefined, retina: boolean) => {
    let parsedWidth: string | number = 'auto';
    if (typeof width === 'string') {
        // Fetch a large image if width was passed as a percent
        if (width.includes('%')) {
            parsedWidth = 1200;
        } else if (width === 'AUTO') {
            parsedWidth = width;
        } else if (!isNaN(parseInt(width))) {
            parsedWidth = parseInt(width);
        } else {
            throw new Error('unsupported-width');
        }
    } else {
        parsedWidth = width || 'auto';
    }

    if (retina) {
        parsedWidth = typeof parsedWidth === 'number' ? parsedWidth * 2 : parsedWidth;
    }

    return parsedWidth;
};

const defaultProps = {
    spacer: true,
};
export const ImageComponent = (componentProps: ImageComponentProps) => {
    const { imagesServiceUrl, supportedImageFormats, isPdfGenerator } = useContext(AppContext);

    if (!componentProps.src) {
        return;
    }

    const props = mergeProps(defaultProps, componentProps);

    const requestingViaPdfBuilder = () => isPdfGenerator || props.forPdfBuilder;

    const retinaWidthToFetch = getImageWidthToFetch(props.width, true);
    const retinaHeightToFetch = !props.height || typeof props.height === 'string' ? 'AUTO' : props.height * 2;
    const originalWidthToFetch = getImageWidthToFetch(props.width, false);
    const originalHeightToFetch = !props.height || typeof props.height === 'string' ? 'AUTO' : props.height;

    let lowResImage: HTMLImageElement | undefined; //eslint-disable-line prefer-const
    let imageFig: HTMLElement | undefined; //eslint-disable-line prefer-const
    let imageDomElement: (HTMLImageElement & { loaded: boolean }) | undefined; //eslint-disable-line prefer-const
    let loadedOnce = false;

    let doneAnimating = false;
    onMount(() => {
        loadedOnce = false;

        if (imageDomElement) {
            imageDomElement.addEventListener(
                'animationend',
                () => {
                    if (!doneAnimating) {
                        doneAnimating = true;
                        // imageDomElement.classList.add(imageFullresTransitionEndedCss);
                        if (lowResImage && imageDomElement) {
                            lowResImage.classList.add(imageLowresTransitionEndedCss);
                            imageDomElement.style.display = 'block';
                        }
                    }
                },
                false
            );
        }

        if (requestingViaPdfBuilder()) {
            // This is the PDF Builder, so we want to load the fullres image right away
            visible(true);
        }
    });

    let done = false;
    let done2 = false;

    const visible = (isVisible: boolean) => {
        if (!isVisible) {
            return;
        }
        if (!done && imageDomElement && !imageDomElement.loaded) {
            done = true;
            const src = getImageServiceSrcPath({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.src,
                sign: props.sign,
                width: originalWidthToFetch,
                height: 'AUTO',
            });

            const img = new Image();
            img.addEventListener(
                'load',
                () => {
                    if (!done2 && imageDomElement) {
                        done2 = true;
                        imageDomElement.src = img.src;
                        imageDomElement.loaded = true;
                        imageDomElement.style.display = 'block';

                        if (requestingViaPdfBuilder()) {
                            imageDomElement.style.opacity = '1';
                        } else {
                            imageDomElement.classList.add(imageFullresLoadedCss);
                        }
                    }
                },
                false
            );
            img.src = src;
        }
    };

    const setImageFigHeight = () => {
        const aspectRatioMap = {
            standard: '16/10',
            wide: '2.35/1',
            portrait: '5/6',
            extraWide: '21/7',
            square: '1/1',
            circle: '1/1',
        };

        let ratio = !props.aspectRatio || props.aspectRatio === 'original' ? props.ratio : aspectRatioMap[props.aspectRatio];

        if (typeof ratio === 'number') ratio = ratio.toString();
        ratio = ratio || '1';

        let corners = props.roundCorners
            ? requestingViaPdfBuilder() ? '0.73rem' : '25px'
            : '0';

        if (props.aspectRatio === 'circle') {
            corners = '100%';
        }

        if (imageFig) {
            imageFig.style.aspectRatio = ratio;
            imageFig.style.borderRadius = corners;
        }
    };

    const loaded = () => {
        if (loadedOnce === false) {
            loadedOnce = true;
            setImageFigHeight();
        }
    };

    const getPictureElement = (altText: string, title: string, imgStyle: {}) => {
        const retinaSrc = getImageServiceSrcPath({
            imagesServiceUrl,
            supportedImageFormats,
            src: props.src,
            sign: props.sign,
            width: retinaWidthToFetch,
            height: retinaHeightToFetch,
        });

        const src = getImageServiceSrcPath({
            imagesServiceUrl,
            supportedImageFormats,
            src: props.src,
            sign: props.sign,
            width: originalWidthToFetch,
            height: originalHeightToFetch,
        });

        const srcSet = `${retinaSrc} 2x, ${src}`;
        return (
            <picture class={imagePictureCss}>
                <source srcset={srcSet} />
                <img loading={requestingViaPdfBuilder() ? 'eager' : 'lazy'} title={title} style={imgStyle} ref={imageDomElement} class={imageFullresCss} alt={altText} />
            </picture>
        );
    };

    const convertDimensionPropToCss = (dimension: string | number | undefined) => {
        const units = ['px', '%', 'em', 'rem', 'vw', 'vh', 'vmin', 'vmax'];
        const unitUsed = units.find((unit) => dimension && dimension.toString().includes(unit));

        if (unitUsed) {
            // Dimension is a string with a unit, so we'll assume it's a valid CSS dimension
            return dimension;
        }

        if (typeof dimension === 'number') {
            // Dimension is a number, so we'll assume it's a number of pixels
            return `${dimension}px`;
        }

        if (dimension && !isNaN(parseInt(dimension))) {
            // Dimension can be converted to a number, so we'll assume it's a number of pixels
            return `${dimension}px`;
        }

        return 'auto'; // We don't recognise the dimension format, so we'll return 'auto'
    };

    
    const getFigureStyle = () => {
        const figureStyle: any = {
            height: convertDimensionPropToCss(props.height),
            width: 'auto',
            display: 'flex',
            'aspect-ratio': props.ratio
        };        
        
        if (!(requestingViaPdfBuilder() && props.gutenberg)) {
            return figureStyle;
        }
        
        /**
         * If we're in the PDF Builder, we want to make sure that the image is not too tall/taking up too much space.
         * We only need to do something if it's coming from wordpress, as any images coming from components have specified dimensions (e.g. ProductIntroPDF)
         */
        const { aspectRatio } = props;

        if (aspectRatio === 'wide' || aspectRatio === 'extraWide') {
            // With these ARs, height is controlled, so we don't need to do anything
            return figureStyle;
        }

        // Scale down images to reduce height
        let newScale = 70;
        if (aspectRatio === 'standard') newScale = 70;
        if (aspectRatio === 'portrait') newScale = 55;
        if (aspectRatio !== 'original') {
            return {
                ...figureStyle,
                height: newScale + '%',
                width: newScale + '%',
            };
        }

        // If the aspect ratio is original, we need to check if the image is taller than it is wide
        const tallImage = props?.ratio && props?.ratio <= 1;

        if (!tallImage) {
            return {
                ...figureStyle,
                height: newScale + '%',
                width: newScale + '%',
            };
        }
        
        // Tall, original AR image needs to be scaled down
        newScale = props.ratio! * 0.70 * 100;

        return {
            ...figureStyle,
            height: newScale + '%',
            width: newScale + '%',
        };
    };


    const imgStyle: any = {
        height: !props.aspectRatio || props.aspectRatio === 'original' ? convertDimensionPropToCss(props.height) : '100%',
        width: '100%',
    };

    if (props.focus) {
        imgStyle['object-position'] = `${props.focus.x * 100}% ${props.focus.y * 100}%`;
    }

    const lowRes = getImageServiceSrcPath({
        imagesServiceUrl,
        supportedImageFormats,
        src: props.src,
        sign: props.sign,
        width: 1,
        height: 1,
    });

    const lowResImgStyle = Object.assign({}, imgStyle);
    lowResImgStyle['padding-bottom'] = convertDimensionPropToCss(props.height) !== 'auto' 
        ? props.height 
        : (1 / (props.ratio || 1)) * 100 + '%';
    lowResImgStyle.background = `url(${lowRes})`;
    lowResImgStyle.height = 0;
    lowResImgStyle['background-size'] = 'contain';

    const imageLowResComponent = <img style={lowResImgStyle} ref={lowResImage} onLoad={loaded} class={imageLowresCss} src={lowRes} alt={props.altText} />;

    // TODO: Not sure about this one. Does it have to be appended down here?
    imgStyle.display = 'none';

    const imageHighResComponent = getPictureElement(props.altText, props.title, imgStyle);

    const imageTextContainer = props.imageText && props.imageText.length > 0 ? <p>{props.imageText}</p> : null;

    const wrapperCss = css`
        height: auto;
    `;

    const imageWrapperStyle: any = {
        width: props.width,
    };

    return (
        <ErrorCatcher componentName="Image component">
            <Show when={props.spacer && requestingViaPdfBuilder()}>
                <StyledVerticalSpace size={1} />
            </Show>
            <ImageWrapper blockSpacing={props.spacer} requestingViaPdfBuilder={requestingViaPdfBuilder()}>
                <IntersectionObserver className={wrapperCss} root=".main-content" onVisible={visible} threshold={props.threshold || 0.1}>
                    <div style={imageWrapperStyle}>
                        <StyledFigure ref={imageFig} style={getFigureStyle()}>
                            {imageLowResComponent}
                            {imageHighResComponent}
                        </StyledFigure>
                        {imageTextContainer}
                    </div>
                </IntersectionObserver>
            </ImageWrapper>
            <Show when={props.spacer && requestingViaPdfBuilder()}>
                <StyledVerticalSpace size={2} />
            </Show>
        </ErrorCatcher>
    );
};

ImageComponent.parseProps = (atts: ImageWordpressBlock) => {
    // This is the raw image object coming from gutenberg. We dont want all details from it.
    const { image } = atts;

    return {
        src: image?.url,
        // Always set this to 100% when we're dealing with a gutenberg image.
        width: '100%',
        height: 'AUTO',
        ratio: image?.ratio,
        sign: image?.sign,
        focus: image?.focus,
        altText: atts?.altText || atts?.imageText || 'Image',
        imageText: atts?.imageText,
        aspectRatio: atts?.aspectRatio,
        roundCorners: atts?.roundCorners,
        spacer: atts?.blockSpacing,
        forPdfBuilder: atts?.forPdfBuilder,
        gutenberg: true,
    };
};
