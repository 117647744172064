import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledVideoContainer = styled.div<{ isLoading?: boolean }>`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-block: 2.5rem;
    animation: fadeIn 0.45s ease-in-out;

    ${(p) => (!p.isLoading ? 'animation: fadeOut 0.25s ease-in-out;' : '')}

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }

    iframe {
        width: 100%;
        height: min-content;
        aspect-ratio: 16 / 9;
        border-radius: 1.2rem;

        ${theme.mediaQueries.mobileMax} {
            border-radius: 0.7rem;
        }
    }
`;

