import { createSignal, onCleanup, onMount, Show, useContext } from 'solid-js';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ImageWordpress } from '../../types/shared';
import { Text } from '../../ui-components/text/text';
import { 
    StyledFaqCard,
    StyledQuestionAndButtonContainer,
    StyledReadMoreButtonContainerDesktop,
    StyledReadMoreButtonContainerTablet,
} from './faq-item.style';
import { VideoData } from '../videos/videos-types';
import { TabHandler } from '../menu/menu-desktop/tab-handling';
import { Modal } from '../../ui-components/modal/modal';
import { FaqContentModal } from './faq-content-modal';

type LinkType = {
    url?: string;
    title?: string;
}

export type FaqItemType = {
    category: string;
    question: string;
    blocks: any[];
    mediaType?: 'image' | 'video';
    image: ImageWordpress;
    video?: VideoData;
    link1?: LinkType;
    link2?: LinkType;
};

type FaqItemProps = {
    faqItem: FaqItemType;
};


export const FaqItem = (props: FaqItemProps) => {
    const { localize, } = useContext(AppContext);

    const [ modalVisible, setModalVisible ] = createSignal(false);

    // Accessibility/keyboard-controls related
    const [ firstTabbableElement, setFirstTabbableElement ] = createSignal<HTMLElement | undefined>();
    const [ lastTabbableElement, setLastTabbableElement ] = createSignal<HTMLElement | undefined>();

    onMount(() => {
        const keydownListener = (e: any) => {
            if (modalVisible() && e.key === 'Escape') {
                setModalVisible(false);
            }
        };

        window.addEventListener('keydown', keydownListener);
        onCleanup(() => window.removeEventListener('keydown', keydownListener));
    });
    
    const ReadMore = () => {
        return (
            <Text
                fontSize='small'
                color={'defer'}
                fontStyle='bold'
                displayRedVerticalLine={false}
            >
                {localize('read-more', 'Read more')}
            </Text>
        );
    };    

    return (
        <StyledFaqCard onClick={() => setModalVisible(true)}>
            <Text fontSize='small' noBlockSpacing>
                {props.faqItem.category}
            </Text>
            <StyledQuestionAndButtonContainer>
                <Text fontSize='larger' noBlockSpacing>
                    {props.faqItem.question}
                </Text>
                <StyledReadMoreButtonContainerDesktop>
                    <ReadMore/>
                </StyledReadMoreButtonContainerDesktop>
            </StyledQuestionAndButtonContainer>

            <StyledReadMoreButtonContainerTablet>
                <ReadMore/>
            </StyledReadMoreButtonContainerTablet>

            <Show when={modalVisible()}>
                <TabHandler refs={{
                    firstTabbableElement,
                    lastTabbableElement,
                }}>
                    <Modal
                        visible={true}
                        onClose={() => setModalVisible(false)}
                        size='full'
                        darkenBackground={true}
                        closeButtonRef={(el: HTMLElement) => setFirstTabbableElement(el)}
                    >
                        <FaqContentModal
                            faqItem={props.faqItem}
                            onClose={() => setModalVisible(false)}
                            setLastTabbableElement={(el: HTMLElement) => setLastTabbableElement(el)}
                        />
                    </Modal>
                </TabHandler>
            </Show>
        </StyledFaqCard>
    );
};

