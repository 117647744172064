/* eslint-disable indent */

import { styled } from 'solid-styled-components';
import { Link } from '@solidjs/router';
import theme, { fontFamilyAtosBold, fontFamilyAtosRegular } from '../../style/theme';
import { StyledLinkProps, StyledLinkAsButtonProps, StyledArrowLinkLabelProps } from './link-types';
import { limitLines } from '../../tools/limit-lines';

const sizeMap = {
    'small': '1.3rem',
    'medium': '1.6rem',
    'large': '1.8rem',
};

type LinkStyleProps = {
    noBlockSpacing?: boolean,
    darkMode?: boolean,
    size?: 'small' | 'medium' | 'large',
    lineLimit?: number,
};

export const linkStyling = (p: LinkStyleProps) => {
    return `
        text-decoration: underline;
        font-size: ${p && p.size ? sizeMap[p.size] : sizeMap.medium};
        letter-spacing: 0;
        line-height: 1.93rem;
        font-family: ${fontFamilyAtosBold};
        font-weight: 800;
        ${p.noBlockSpacing ? '' : 'margin: 1.43rem 0;'}
        color: ${theme.palette.pink};
        transition: 0.2s color;

        ${p.darkMode ? `
            color: ${theme.palette.white};
            &:hover {
                color: ${theme.palette.pink};
            }
        ` : ''}

        ${(p.lineLimit ? limitLines(p.lineLimit) : '')}
    `;
};

export const StyledUtilityLink = styled.a<LinkStyleProps>`
    ${p => linkStyling(p)}
    cursor: pointer;

    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
    ${p => linkStyling(p)}
    ${p => p.active ? `color: ${theme.palette.redDark};` : ''}

    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledAnchorLink = styled.a<StyledLinkProps>`
    ${p => linkStyling(p)}
    ${p => p.active ? `color: ${theme.palette.redDark};` : ''}
    cursor: pointer;

    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledArrowLinkLabel = styled.span<StyledArrowLinkLabelProps>`
    ${p => p.arrowPosition === 'left' ? 'padding-left: 0.5rem;' : ''}
    ${p => p.arrowPosition === 'right' ? 'padding-right: 0.8rem;' : ''}

    ${(p) =>
        p.breakWords
            ? `
        width: 100%;
        overflow-wrap: break-word;
    `
            : ''}
`;

export const arrowLinkStyle = (p: StyledLinkProps) => `
    text-decoration: none;
    font-size: ${p.size ? sizeMap[p.size] : sizeMap.medium};
    font-family: ${p.bold ? fontFamilyAtosBold : fontFamilyAtosRegular};
    ${p.bold ? 'font-weight: 800;' : ''}
    display: flex;
    align-items: center;
    color: ${p.labelColor ? p.labelColor : theme.palette.gray};
    transition: 0.2s color;

    &:hover {
        color: ${theme.palette.pink};
        & svg {
            fill: ${theme.palette.pink};
        }
    }
`;

export const StyledArrowIcon = styled.div`
    margin-top: 2px;
`;

export const StyledArrowLink = styled(Link)<StyledLinkProps>`
    ${p => arrowLinkStyle(p)}
    ${p => p.active ? `color: ${theme.palette.redDark};` : ''}
`;

export const StyledArrowLinkAsButton = styled.a<StyledLinkProps>`
    cursor: pointer;
    ${p => arrowLinkStyle(p)}
`;

const linkAsButtonStyling = `
    line-height: 2.3rem;
    cursor: pointer;
    color: ${theme.palette.redDark};
    transition: 0.2s color;
    &:hover {
        color: ${theme.palette.pink};
    }
`;

export const StyledLinkAsButton = styled.button<StyledLinkAsButtonProps>`
    ${p => linkStyling(p)}
    ${linkAsButtonStyling}
`;

export const StyledLinkAsDiv = styled.div<StyledLinkAsButtonProps>`
    ${p => linkStyling(p)}
    ${linkAsButtonStyling}
`;
