/* eslint-disable indent */

import { theme } from '../../style/theme';
import { containerQueries } from '../../tools/check-container-support';
import { sidebarPaddingRightTablet } from '../../ui-components/layouts/sidebar.style';

const {grid} = theme;

export const smallDesktopCss = (withSidebarMenu: boolean) => {
    return `
        ${withSidebarMenu ? `
            padding-left: calc(${sidebarPaddingRightTablet}rem + ${grid.gapSize}rem);
            margin-left: calc((${sidebarPaddingRightTablet}rem + ${grid.gapSize}rem) * -1);

            padding-right: 55%;
            margin-right: -55%;

            @media only screen and (max-width: 1830px) {
                padding-right: 35%;
                margin-right: -35%;
            }
            @media only screen and (max-width: 1520px) {
                padding-right: 25%;
                margin-right: -25%;
            }
            @media only screen and (max-width: 1270px) {
                padding-right: 20%;
                margin-right: -20%;
            }

            ${containerQueries('tabletMax')} {
                @media only screen and (max-width: 600px) {
                    margin-left: calc((${sidebarPaddingRightTablet}rem + ${grid.gapSize}rem) * -1) !important;
                }
                margin-left: calc((${grid.gapSize}rem) * -1);
            }            
        ` : ''}

        ${containerQueries('desktopMax')} {
            padding-top: ${grid.standardUnit}rem;
            padding-bottom: ${grid.standardUnit}rem;

            &:has( > .atos-column.with-bg-color) {
                padding-top: ${grid.standardUnit}rem;
                padding-bottom: ${grid.standardUnit}rem;
            }
        }
    `;
};

export const tabletCss = () => {
    return `
        ${containerQueries('tabletMax')} {
            .atos-column:not(.with-divider) + .atos-column {
                &:not(.with-bg-color) {
                    padding-top: 0;
                }
            }

            &:has( > .atos-column.with-bg-color:first-of-type) {
                padding-top: ${grid.standardUnit}rem;
            }

            &:has( > .atos-column.with-bg-color:last-of-type) {
                padding-bottom: ${grid.standardUnit}rem;
            }

            .atos-column:not(.with-divider):has(+ .atos-column) {
                &:not(.with-bg-color) {
                    padding-bottom: 0px;
                }
            }
        }
    `;
};