import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledFaqSelector = styled.div`
    margin-block: 1rem;
`;

export const StyledHeadingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.7rem;

    ${theme.mediaQueries.tabletMax} {
        justify-content: center;
        margin-bottom: 1rem;
    }
`;

