import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledModalContainer = styled.div`
    text-align: left;
    padding-inline: 2rem;

    ${theme.mediaQueries.mobileMax} {
        padding-inline: 0;
    }
`;

export const StyledModalHeader = styled.div`
    border-bottom: 1px solid ${theme.palette.lightGray};
    padding-bottom: 1.5rem;
    margin-bottom: 2.5rem;
`;

export const StyledModalBody = styled.div`
    display: flex;
    column-gap: 7rem;
    text-align: left;

    ${theme.mediaQueries.tabletMaxOriginal} {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;

export const StyledBlocksContainer = styled.div`
    width: 50%;

    ${theme.mediaQueries.tabletMaxOriginal} {
        width: auto;
    }
`;

export const StyledMediaContainer = styled.div`
    max-width: 43rem;
    margin-top: 2rem;
    width: 50%;
    
    ${theme.mediaQueries.tabletMaxOriginal} {
        width: 100%;
        max-width: 100%;
        margin-top: 1rem;
        margin-bottom: 4rem;
    }
`;

export const StyledLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`;

