import { styled } from 'solid-styled-components';
import theme, { fontFamilyAtosBold } from '../../style/theme';
import { StyledButtonProps } from './button-types';
import { Link } from '@solidjs/router';

const alignmentMap = {
    'left': 'flex-start',
    'center': 'center',
    'right': 'flex-end',
};

const common = `
    color: white;
    border-radius: 4.29rem;
    margin-block: 0.71rem;
    cursor: pointer;
    border: 2px solid transparent;
    letter-spacing: 0.45px;
    font-family: ${fontFamilyAtosBold};
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    width: fit-content;
    line-height: 1.57rem;
    font-size: clamp(1.14rem, 1.14rem + 1.14vw,  1.29rem);
    padding-inline: clamp(3.36rem, 3.36rem + 0.35vw, 4.29rem);
    padding-block: clamp(1.21rem, 1.21rem + 1.21vw, 1.79rem);
    transition: background 0.2s;

    &:hover {
        background: ${theme.palette.pink};
    }

    &:active {
        background: ${theme.palette.lightPink};
    }

    ${theme.mediaQueries.mobileMax} {
        padding-inline: 3.36rem;
        padding-block: 1.5rem;
        font-size: 1.4rem;
        letter-spacing: 0.4px;
        line-height: 1.36rem;
    }
`;

const commonActive = `
    background: ${theme.palette.lightPink};
`;

const secondary = `
    background: none;
    border: 2px solid ${theme.palette.redDark};
    color: ${theme.palette.redDark};
    transition: background 0.2s, border 0.2s, color 0.2s;

    &:hover {
        background: none;
        border: 2px solid ${theme.palette.pink};
        color: ${theme.palette.pink};
    }

    &:active {
        background: none;
        border: 2px solid ${theme.palette.lightPink};
        color: ${theme.palette.lightPink};
    }
`;

const secondaryActive = `
    background: none;
    border: 2px solid ${theme.palette.lightPink};
    color: ${theme.palette.lightPink};
`;

const tertiary = `
    background: none;
    border: none;
    color: ${theme.palette.redDark};
    text-decoration: underline;
    padding: 0 !important;
    font-size: 1.43rem;
    letter-spacing: 0;
    line-height: 1.93rem;
    transition: color 0.2s;

    &:hover {
        background: none;
        color: ${theme.palette.pink};
    }

    &:active {
        background: none;
        color: ${theme.palette.lightPink};
    }

    ${theme.mediaQueries.mobileMax} {
        padding: 0;
    }
`;

const tertiaryActive = `
    background: none;
    color: ${theme.palette.lightPink};
`;

const darkMode = `
    background: transparent;
    border: 2px solid ${theme.palette.white};
    color: ${theme.palette.white};
    transition: background 0.2s, color 0.2s;

    &:hover {
        background: ${theme.palette.white};
        color: ${theme.palette.darkGray};
        border: 2px solid ${theme.palette.white};
    }

    &:active {
        background: none;
        border: 2px solid ${theme.palette.lightPink};
        color: ${theme.palette.lightPink};
    }
`;

const darkActive = `
    background: none;
    border: 2px solid ${theme.palette.lightPink};
    color: ${theme.palette.lightPink};
`;

const darkAndTertiary = `
    background: none;
    border: none;
    color: ${theme.palette.white};
    text-decoration: underline;
    padding: 0;
    transition: color 0.2s;

    &:hover {
        background: none;
        border: none;
        color: ${theme.palette.pink};
    }

    &:active {
        background: none;
        border: none;
        color: ${theme.palette.lightPink};
    }
`;

const darkAndTertiaryActive = `
    background: none;
    border: 2px solid ${theme.palette.lightPink};
    color: ${theme.palette.lightPink};
`;

// align-self: center or flex-end
export const StyledButton = styled.button<StyledButtonProps>`
    ${common};

    ${(p) => (p.isActive ? commonActive : '')}

    ${(p) => (p.variant === 'secondary' ? secondary : '')}
    ${(p) => (p.variant === 'secondary' && p.isActive ? secondaryActive : '')}

    ${(p) => (p.variant === 'tertiary' ? tertiary : '')}
    ${(p) => (p.variant === 'tertiary' && p.isActive ? tertiaryActive : '')}

    ${(p) => (p.darkMode === true ? darkMode : '')}
    ${(p) => (p.darkMode === true && p.isActive ? darkActive : '')}

    ${(p) => (p.darkMode === true && p.variant === 'tertiary' ? darkAndTertiary : '')}
    ${(p) => (p.darkMode === true && p.variant === 'tertiary' && p.isActive ? darkAndTertiaryActive : '')}

    ${(p) => (p.variant === 'tertiary' && p.noCaps ? 'text-transform: none;' : '')}

    ${(p) => (p.noBlockSpacing ? 'margin-block: 0;' : '')}
    
    ${(p) => (p.horizontalAlignment ? `align-self: ${alignmentMap[ p.horizontalAlignment ]};` : '')}
    
    ${(p) => (p.positionToBottom ? 'margin-top: auto;' : '')}

    ${(p) => (p.variant === 'primary' && p.isDisabled
        ? `
            pointer-events: none;
            background-color: ${theme.palette.lightGray};
        ` : ''
    )}

    ${(p) => (p.variant === 'tertiary' && p.isDisabled
        ? `
            pointer-events: none;
            color: ${theme.palette.lightGray};
        ` : ''
    )}

    ${(p) => (p.small ? `
        padding-inline: 1.15rem;
        padding-block: 0.60rem;
        font-size: 1.14rem;
        ${theme.mediaQueries.mobileMax} {
            padding-inline: 1.5rem;
            padding-block: 0.60rem;
            font-size: 1.14rem;
        }
    ` : '')}
    
    ${(p) => (p.customCss ? p.customCss : '')}

`;

export const StyledLink = styled(Link) <StyledButtonProps>`
    display: block;
    text-decoration: none;
    ${common};
    ${(p) => (p.isActive ? commonActive : '')}

    ${(p) => (p.variant === 'secondary' ? secondary : '')}
    ${(p) => (p.variant === 'secondary' && p.isActive ? secondaryActive : '')}


    ${(p) => (p.variant === 'tertiary' ? tertiary : '')}
    ${(p) => (p.variant === 'tertiary' && p.isActive ? tertiaryActive : '')}


    ${(p) => (p.darkMode === true ? darkMode : '')}
    ${(p) => (p.darkMode === true && p.isActive ? darkActive : '')}

    ${(p) => (p.darkMode === true && p.variant === 'tertiary' ? darkAndTertiary : '')}
    ${(p) => (p.darkMode === true && p.variant === 'tertiary' && p.isActive ? darkAndTertiaryActive : '')}
    
    ${(p) => (p.horizontalAlignment ? `align-self: ${alignmentMap[ p.horizontalAlignment ]};` : '')}

    ${(p) => (p.positionToBottom ? 'margin-top: auto;' : '')}

    ${(p) => (p.noBlockSpacing ? 'margin-block: 0;' : '')}    

    ${(p) => (p.small ? `
        padding-inline: 1.5rem;
        padding-block: 0.60rem;
        font-size: 1.14rem;
        ${theme.mediaQueries.mobileMax} {
            padding-inline: 1.5rem;
            padding-block: 0.60rem;
            font-size: 1.14rem;
        }
    ` : '')}
    
    ${(p) => (p.customCss ? p.customCss : '')}
`;
