import { Link } from '@solidjs/router';
import { createSignal, Match, Switch } from 'solid-js';
import { DOMElement } from 'solid-js/jsx-runtime';
import { ErrorCatcher } from '../../../tools/error-catcher';
import { Heading } from '../../../ui-components/heading/heading';
import { FacebookIcon } from '../../icons-library/social-media/facebook';
import { InstagramIcon } from '../../icons-library/social-media/instagram';
import { LinkedinIcon } from '../../icons-library/social-media/linkedin';
import { TwitterIcon } from '../../icons-library/social-media/twitter';
import IntersectionObserver from '../../intersection-observer/intersection-observer';
import {
    StyledAuthorContainer,
    StyledCardText,
    StyledFeedCardInner,
    StyledFeedCardWrapper,
    StyledIconContainer,
    StyledIconsContainer,
} from './feed-card.styles';
import { Text } from '../../../ui-components/text/text';

import { removeTrailingSlash } from '../../../tools/remove-trailing-slash';

export const FeedCard = (props: any) => {
    const [background, setBackground] = createSignal('/assets/atos-logotype.svg');
    const [useBackgroundFallback, setUseBackgroundFallback] = createSignal(true);

    const hasExpired = (expiresAt: string) => {
        if (!expiresAt) {
            return false;
        }
        
        const expirationDate = new Date(expiresAt);
        return expirationDate < new Date();
    };

    const showImage = (visible: boolean | DOMElement) => {
        if (!visible || !props?.image?.url || (props?.image?.expiresAt && hasExpired(props?.image?.expiresAt))) {            
            return;
        }

        setUseBackgroundFallback(false);
        setBackground(props?.image?.url);
    };

    // We use the intersection observer here to defer images so they arent loaded until the component is visible,
    // oherwise, we get a penalty from lighthouse because of old image formats that we can control (ince they are external)

    return (
        <ErrorCatcher componentName="Social media feed Card">
            <IntersectionObserver onVisible={showImage} threshold={.1}>
                <Link href={removeTrailingSlash(props.url)} target="_blank" rel="noopener noreferrer">
                    <StyledFeedCardWrapper useBackgroundFallback={useBackgroundFallback()} imageUrl={background()}>
                        <StyledFeedCardInner>
                            <StyledCardText>{props.text}</StyledCardText>
                            <StyledIconsContainer>
                                <StyledIconContainer>
                                    <Switch fallback={<p>icon</p>}>
                                        <Match when={props.icon === 'twitter'}>
                                            <TwitterIcon />
                                        </Match>
                                        <Match when={props.icon === 'facebook'}>
                                            <FacebookIcon />
                                        </Match>
                                        <Match when={props.icon === 'linkedin'}>
                                            <LinkedinIcon />
                                        </Match>
                                        <Match when={props.icon === 'instagram'}>
                                            <InstagramIcon />
                                        </Match>
                                    </Switch>
                                </StyledIconContainer>
                                <StyledAuthorContainer class="username">
                                    <Text fontSize="small" color={'darkGray'} noBlockSpacing={true} displayRedVerticalLine={false} fontStyle={'bold'}>
                                        {props.username}
                                    </Text>
                                </StyledAuthorContainer>
                            </StyledIconsContainer>
                        </StyledFeedCardInner>
                    </StyledFeedCardWrapper>
                </Link>
            </IntersectionObserver>
        </ErrorCatcher>
    );
};
