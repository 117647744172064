import { styled } from 'solid-styled-components';
import theme from '../../style/theme';
import { DividerComponentStyleProps, DividerMarginSizes } from './divider-types';

const { palette } = theme;

const marginSizes: DividerMarginSizes = {
    largest: '2.5rem',
    larger: '2rem',
    default: '1.5rem',
    smaller: '1rem',
    smallest: '0.5rem'
};

const getAggregateHeight = (
    marginSizeTop?: keyof DividerMarginSizes | 'default',
    marginSizeBottom?: keyof DividerMarginSizes | 'default',
) => {
    const top = marginSizes[marginSizeTop!];
    const bottom = marginSizes[marginSizeBottom!];
    return `calc(${top} + ${bottom})`;
};

export const StyledDivider = styled.div<DividerComponentStyleProps>`
    position: relative;

    ${p => p.forPdfBuilder ? `
        height: ${getAggregateHeight(p.marginSizeTop, p.marginSizeBottom)};
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: ${p.manualWidth ? `${p.width}%` : '100%'};
            height: ${p.color === 'red' ? '2px' : '1px'};
            background-color: ${p.isTransparent ? 'transparent' : p.color ? palette[p.color] : palette.gray};
        }
    ` : `
        height: ${p.color === 'red' ? '2px' : '1px'};
        width: ${p.manualWidth ? `${p.width}%` : '100%'};
        background-color: ${p.isTransparent ? 'transparent' : p.color ? palette[p.color] : palette.gray};
        margin-top: ${p.marginSizeTop ? marginSizes[p.marginSizeTop] : marginSizes['default']};
        margin-bottom: ${p.marginSizeBottom ? marginSizes[p.marginSizeBottom] : marginSizes['default']};
    `}
`;