import { TextProps, TextWordpressBlock } from './text.d';
import { StyledParagraph } from './text.styles';
import { createEffect, For, Match, mergeProps, Switch } from 'solid-js';
import { ErrorCatcher } from '../../tools/error-catcher';
import { ColorName } from '../../style/theme';
import removeAmpersand from '../../tools/remove-ampersand';

const defaultProps = {
    color: 'darkestGray' as ColorName,
    fontSize: 'normal',
    displayRedVerticalLine: false,
    underlined: false,
};

const splitTextBlockToParagraphs = (textBlock: string) => {    
    if (!textBlock.includes('\r\n\r')) {
        return [textBlock];
    }
    
    return textBlock.split('\r\n\r');
};

export const Text = (componentProps: TextProps) => {

    const props = mergeProps(defaultProps, componentProps);

    // eslint-disable-next-line
    let ref:HTMLParagraphElement | undefined = undefined;

    if (!props.children) {
        return null;
    }

    const classToUse = () => {
        if (props.pdfBuilderText) {
            return '';
        }

        return props.displayRedVerticalLine ? 'atos-lined-text' : 'atos-text';
    };

    // This will make sure that any tags with the attachment datatype are opened in a new tab.
    // The main reason behind it is to make sure that files are opened without solidjs
    createEffect(() => {
        ref?.querySelectorAll('[data-type="attachment"]').forEach((atag: any) => {
            atag.target = '_blank';
        });
    });

    return (
        <ErrorCatcher componentName='Text'>
            <Switch fallback={
                <StyledParagraph
                    fontSize={props.fontSize}
                    ref={ref}
                    fontStyle={props.fontStyle}
                    noBlockSpacing={props.noBlockSpacing}
                    underlined={props.underlined}
                    align={props.align}
                    displayRedVerticalLine={props.displayRedVerticalLine}
                    color={props.color}
                    innerHTML={removeAmpersand(props.children)}
                    lineLimit={props.lineLimit}
                    pdfBuilderText={props.pdfBuilderText}
                    isLastParagraphPdf={props.isLastParagraphPdf}
                    class={classToUse()}
                />
            }>
                <Match when={props.splitParagraph}>
                    <For each={splitTextBlockToParagraphs(props.children)}>{(text => (
                        <StyledParagraph
                            fontSize={props.fontSize}
                            ref={ref}
                            fontStyle={props.fontStyle}
                            noBlockSpacing={props.noBlockSpacing}
                            underlined={props.underlined}
                            align={props.align}
                            displayRedVerticalLine={props.displayRedVerticalLine}
                            color={props.color}
                            innerHTML={removeAmpersand(text)}
                            lineLimit={props.lineLimit}
                            pdfBuilderText={props.pdfBuilderText}
                            isLastParagraphPdf={props.isLastParagraphPdf}
                            class={classToUse()}
                        />
                    ))}</For>
                </Match>
            </Switch>
        </ErrorCatcher>
    );
};

Text.parseProps = (atts: TextWordpressBlock) => {
    return {
        ...atts,
        color: Array.isArray(atts.color) ? undefined : atts.color?.color,
    };
};
