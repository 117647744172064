import { For } from 'solid-js';
import { Heading } from '../../ui-components/heading/heading';
import { StyledFaqSelector, StyledHeadingContainer } from './faq-selector.style';
import { ArrowDownIcon } from '../icons-library/arrow-down';
import theme from '../../style/theme';
import { FaqItem, FaqItemType } from './faq-item';

type FaqSelectorProps = {
    heading: string;
    faqItems: FaqItemType[];
};

export const FaqSelector = (props: FaqSelectorProps) => {

    return (
        <StyledFaqSelector>

            <StyledHeadingContainer>
                <Heading tag='h2' variant='small' noBlockSpacing>
                    {props.heading}
                </Heading>
                <ArrowDownIcon fill={theme.palette.red} width={1.1} height={1.1} />
            </StyledHeadingContainer>

            <For each={props.faqItems}>{(faqItem) => (
                <FaqItem faqItem={faqItem} />
            )}</For>

        </StyledFaqSelector>
    );
};

FaqSelector.parseProps = (atts: any) => {
    return {
        heading: atts.heading,
        faqItems: atts.faqItems,
    };
};

