import { SectionProps } from './section-types';
import { StyledPennant, StyledPennantWrapper, StyledSection } from './section.style';
import { getChildSpans } from '../helpers';
import { children, createSignal, createRenderEffect, mergeProps, onMount, useContext, Show } from 'solid-js';
import { getBackgroundImageSrcData, ImageSrcData } from '../../components/image-component/image-component';
import { AppContext } from '../../app-context-provider/app-context-provider';
import { ErrorCatcher } from '../../tools/error-catcher';
import { focusPosition } from '../../tools/get-image-focus';
import { PageContext } from '../../components/page/page-context-provider';
import theme from '../../style/theme';
import { DocumentTypeFlag } from '../../components/document-type-flag/document-type-flag';
import { Grid } from '../grid/grid';

const defaultProps = {
    isLayoutSection: true,
    autoResponsiveness: true,
    removeGridGap: false,
    equalHeight: false,
};

export const Section = (componentProps: SectionProps) => {
    const { 
        imagesServiceUrl,
        supportedImageFormats,
        viewport,
        appStore,
        headerSettings,
    } = useContext(AppContext);

    const { withSidebarMenu, hasHero } = useContext(PageContext); 
    const [ backgroundImageSrcData, setBackgroundImageSrcData ] = createSignal<ImageSrcData>();

    const props = mergeProps(defaultProps, componentProps);
    const spans = getChildSpans({
        templateShorthand: props.templateShorthand,
        template: props.template
    });
    
    const [childCount, setChildCount] = createSignal(spans.length);

    const resolved = children(() => props.children);
    createRenderEffect(() => {
        const list = resolved.toArray();
        setChildCount(list.length);
    });

    const imageWidth = () => {
        // Because this is a background image which sizes itself to the container, we need to set the width larger than the container, so it has room to grow/shrink.
        if (viewport.width <= theme.breakpoints.MOBILE) {            
            return 480; // Mobile
        }

        if (viewport.width <= theme.breakpoints.TABLET && viewport.width > theme.breakpoints.MOBILE) {
            return 960; // Tablet
        }

        if (viewport.width <= theme.breakpoints.DESKTOP_LARGE && viewport.width > theme.breakpoints.TABLET) {
            return 1500; // Small desktop
        }

        return 1920; // Desktop
    };

    onMount(() => {
        if (props.backgroundType === 'image' && props.backgroundValue ) {
            const backgroundImage: ImageSrcData = getBackgroundImageSrcData({
                imagesServiceUrl,
                supportedImageFormats,
                src: props.backgroundValue.url,
                width: imageWidth() || 480, // Assume mobile from the start
                height: 'AUTO',
            });

            setBackgroundImageSrcData(backgroundImage);   
        }
    }); 

    const isOriginalTablet = () => viewport.width <= theme.breakpoints.ORIGINAL_TABLET;

    const adjustForHcpHeader = () => (
        appStore.isHcp()
        && headerSettings.activateHcpHeader
        && !hasHero()
        && !withSidebarMenu()
        && !isOriginalTablet()
    );

    return (
        <ErrorCatcher componentName='Section'>

            <Show when={props.pennantActive}>
                <Grid
                    templateShorthand={[ 12 ]}
                    isLayoutGrid={!withSidebarMenu()}
                    customCss='height: 0;'
                >
                    <StyledPennantWrapper>
                        <StyledPennant
                            class='atos-pennant'
                            displaySide={props.pennantSide}
                            withSidebar={withSidebarMenu()}
                        >
                            <DocumentTypeFlag
                                type="event"
                                size="large"
                                displaySide={props.pennantSide}
                                customText={props.pennantText}
                            />
                        </StyledPennant>
                    </StyledPennantWrapper>
                </Grid>
            </Show>

            <StyledSection
                {...props.sectionIdentifier && {
                    id: props.sectionIdentifier
                }}
                class={'atos-section'}
                classList={{
                    'pad-top-for-hcp-header': adjustForHcpHeader(),
                    'section-with-color': props.backgroundType === 'color',
                }}
                isLayoutGrid={typeof props.isLayoutSection === 'undefined' ? defaultProps.isLayoutSection : props.isLayoutSection}
                autoResponsiveness={typeof props.autoResponsiveness === 'undefined' ? defaultProps.autoResponsiveness : props.autoResponsiveness}
                backgroundType={props.backgroundType}
                backgroundValue={props.backgroundValue}
                backgroundImageSrcData={backgroundImageSrcData()}
                widthType={props.widthType}
                heightType={props.heightType}
                customHeight={props.customHeight}
                horizontalAlignment={props.horizontalAlignment}
                verticalAlignment={props.verticalAlignment}
                equalHeight={props.equalHeight}
                removeSpacingBlock={props.removeSpacingBlock}
                childSpans={spans}
                childCount={childCount()}
                customCss={props.customCss}
                combineWithBelowSection={props.combineWithBelowSection}
                focusPosition={focusPosition(props?.backgroundValue?.focus)}
                withSidebarMenu={withSidebarMenu()}
                overlayType={props.overlayType}
                withPennant={props.pennantActive}
            >
                {resolved()}
            </StyledSection>
        </ErrorCatcher>
    );

};

Section.parseProps = (atts: any) => {
    // pennantSide default is undefined and should be interpreted as 'left'
    const pennantSide = atts.pennantSide ? atts.pennantSide : 'left';

    return {
        backgroundType: atts.backgroundType,
        backgroundValue: atts.backgroundValue,
        template: atts.template,
        widthType: atts.widthTypeFull ? 'full' : 'bgFull',
        colorSelection: atts.colorSelection,
        equalHeight: atts.equalHeight,
        horizontalAlignment: atts.horizontalAlignment,
        verticalAlignment: atts.verticalAlignment,
        roundCorners: atts.roundCorners,
        sectionIdentifier: atts.sectionIdentifier,
        isLayoutSection: atts.isLayoutSection,
        autoResponsiveness: atts.autoResponsiveness,
        combineWithBelowSection: atts.combineWithBelow,
        overlayType: atts.overlayType,

        pennantActive: atts.pennantActive,
        pennantText: atts.pennantText,
        pennantSide,
    };
};
