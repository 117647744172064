import { styled } from 'solid-styled-components';
import theme from '../../style/theme';

export const StyledFaqCard = styled.button`
    display: block;
    width: 100%;
    text-align: left;
    background: ${theme.palette.white};
    border-radius: 1rem;
    margin-bottom: 2rem;
    padding: 1.5rem 3rem;
    max-width: 58rem;
    transition: box-shadow 0.1s ease-in-out, color 0.1s ease-in-out;

    color: ${theme.palette.gray};

    &:hover {
        box-shadow: 0px 0.41rem 0.62rem rgba(0,0,0,0.1);
        color: ${theme.palette.red};
    }

    ${theme.containerQueries.tabletMax} {
        padding: 1.5rem 2rem;
    }
`;

export const StyledQuestionAndButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    margin-top: 0.3rem;
`;

export const StyledReadMoreButtonContainerDesktop = styled.div`
    white-space: nowrap;
    text-transform: uppercase;

    ${theme.containerQueries.tabletMax} {
        display: none;
    }
`;

export const StyledReadMoreButtonContainerTablet = styled.div`
    white-space: nowrap;
    text-transform: uppercase;
    display: none;
    margin-top: 1rem;
    text-align: right;

    ${theme.containerQueries.tabletMax} {
        display: block;
    }

    ${theme.containerQueries.mobileMax} {
        color: ${theme.palette.red};
    }
`;

